<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="studentInstallments"
      sort-by="name"
      class="elevation-1"
      :loading="isGettingData"
      loading-text="يتم جلب البيانات ...الرجاء الانتظار"
    >
      <template v-slot:body.prepend>
        <tr>
          <td colspan="4">
            <v-autocomplete
              v-model="selectedStudentId"
              :items="students"
              :loading="loadingStudents"
              :search-input.sync="search"
              chips
              clearable
              hide-details
              hide-selected
              item-text="name"
              item-value="id"
              placeholder="اختر طالب"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    البحث عن
                    <strong>الطلاب</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
                >
                  <span v-text="item.name"></span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                  color="indigo"
                  class="headline font-weight-light white--text"
                ></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </td>
          <td>
            <v-btn
              class="ma-2"
              outlined
              color="primary"
              @click="getStudentInstallment"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>الأقساط</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            class="d-none d-sm-flex"
            @click="showAddNewInstallment = true"
            bottom
            color="blue darken-4"
            dark
            fab
            fixed
            left
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-dialog v-model="dialog" width="700px">
            <v-card>
              <v-card-title class="accent white--text darken-2"
                >تعديل بيانات القسط</v-card-title
              >
              <v-container>
                <v-row>
                  <div>
                    <v-card flat width="700px">
                      <v-card-text>
                        <v-form ref="form" v-model="valid">
                          <v-container>
                            <v-row class="mb-2">
                              <v-col cols="12">
                                <v-text-field
                                  label="تاريخ الاستحقاق"
                                  :value="editedItem.dueDate"
                                  type="date"
                                  v-model="editedItem.dueDate"
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row class="mb-2">
                              <v-col cols="12">
                                <v-text-field
                                  label="تاريخ الدفع"
                                  value="1/1/001"
                                  type="date"
                                  v-model="editedItem.paymentDate"
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row class="mb-2">
                              <v-col cols="12">
                                <v-text-field
                                  label="المبلغ"
                                  type="number"
                                  v-model="editedItem.amount"
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row class="mb-2">
                              <v-col cols="12">
                                <v-checkbox
                                  label="تم الدفع"
                                  v-model="editedItem.isPaid"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer />

                <v-btn color="blue darken-1" text @click="close">الغاء</v-btn>
                <v-btn color="blue darken-1" text @click="save">حفظ</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.isPaid="{ item }">
        <v-icon v-if="item.isPaid" color="green">mdi-check</v-icon>
        <v-icon v-if="!item.isPaid" color="red">mdi-close</v-icon>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
          rounded
          color="primary"
          dark
          @click="editStudentInstallment(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          rounded
          class="mr-2"
          color="error"
          @click="deleteStudentInstallment(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <AddStudentInstallment
      :show.sync="showAddNewInstallment"
      :studentId="selectedStudentId"
      @studentInstallmentAdded="refreshData()"
    />
  </div>
</template>

<script>
import { APIService } from "../../services/apiServices";
import AddStudentInstallment from "../studentInstallments/AddStudentInstallment.vue";
import debounce from "debounce";
const apiService = new APIService();
export default {
  components: { AddStudentInstallment },
  data: () => ({
    dialog: false,
    valid: true,
    showAddNewInstallment: false,
    isGettingData: false,
    searchkey: "",
    headers: [
      { text: "تاريخ الاستحقاق", value: "dueDate" },
      { text: "تاريخ الدفع", value: "paymentDate" },
      { text: "المبلغ", value: "amount" },
      { text: "تم الدفع", value: "isPaid" },
      { text: "العمليات", value: "action", sortable: false },
    ],

    exportHeaders: {
      "Due Date": "dueDate",
      "Payment Date": "paymentDate",
      Amount: "amount",
      "Is Paid": "isPaid",
    },
    editedIndex: -1,
    editedItem: {
      paymentDate: "",
      amount: 0,
      isPaid: false,
       },
    defaultItem: {
      paymentDate: "",
      amount: 0,
      isPaid: false,
    },
    selectedStudentId: 0,
    students: [],
    search: "",
    studentInstallments: [],
    loadingStudents: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "طالب جديد" : "تعديل";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search(value) {
      if (!value) {
        return;
      }

      debounce(this.makeSearchStudent, 200)(value, this);
    },
  },

  created() {},

  methods: {
    makeSearchStudent: async (value, self) => {
      // Handle empty value
      if (!value) {
        this.students = [];
      }

      if (self.loadingStudents) {
        return;
      }

      await apiService
        .searchStudents(0, value)
        .then((response) => {
          self.students = response.students;
        })
        .catch((error) => {
          self.error = "Unknown Error. Please check details and try again.";
          alert(error);
          self.failed();
        })
        .finally(() => (self.loadingLearner = false));
    },

    getStudentInstallment() {
      this.isGettingData = true;
      const that = this;
      apiService
        .getStudentInstallment(this.selectedStudentId)
        .then((response) => {
          that.isGettingData = false;
          this.studentInstallments = response.studentInstallments;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },

    refreshData() {
      this.getStudentInstallment();
    },

    deleteStudentInstallment(item) {
      var studentInstallmentObject = {
        Id: item.id,
      };
      if (confirm("هل تريد بالتأكيد حذف القسط")) {
        setTimeout(() => {
          apiService
            .deleteStudentInstallment(studentInstallmentObject)
            .then(() => {
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },

    editStudentInstallment(item) {
      this.editedIndex = this.studentInstallments.indexOf(item);
      this.editedItem.id = item.id;
      this.editedItem.dueDate = item.dueDate;
      this.editedItem.paymentDate = item.paymentDate;
      this.editedItem.amount = item.amount;
      this.editedItem.isPaid = item.isPaid;

      this.dialog = true;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        this.isClick = true;
        this.editedItem.studentId=this.selectedStudentId;
        setTimeout(() => {
          apiService
            .UpdateStudentInstallment(this.editedItem)
            .then(() => {
              this.isClick = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              this.close();
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
        // } else {
        //   this.desserts.push(this.editedItem);
        // }
      }
    },
  },
};
</script>
