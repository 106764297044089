<template>
  <v-dialog v-model="dialog" width="400px">
    <v-card>
      <v-card-title class="accent white--text darken-2">الاقساط</v-card-title>
      <v-container>
        <v-row justify="center">
          <div>
            <v-card flat width="300px" class="mx-auto">
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-text-field
                        label="تاريخ الاستحقاق"
                        value="1/1/001"
                        type="date"
                        v-model="installment.dueDate"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-text-field
                        label="تاريخ الدفع"
                        value="1/1/001"
                        type="date"
                        v-model="installment.paymentDate"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-text-field
                        label="المبلغ"
                        type="number"
                        v-model="installment.amount"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-checkbox
                        label="تم الدفع"
                        v-model="installment.isPaid"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer />

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="addInstallment"
          :loading="isClick"
          >حفظ</v-btn
        >
        <v-btn text @click="dialog = false">الغاء</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//new
import { APIService } from "../../services/apiServices";
const apiService = new APIService();
export default {
  props: {
    show: Boolean,
    studentId: Number,
  },
  data: () => ({
    valid: true,
    addEnable: true,
    isClick: false,
    dialog: false,
    show1: false,

    installment: {
      id: 0,
      dueDate: "",
      paymentDate: "",
      amount: 0,
      isPaid: false,
      studentId: 0,
    },
  }),
  methods: {
    addInstallment() {
      if (this.$refs.form.validate()) {
        this.isClick = true;
        this.addEnable = true;

        setTimeout(() => {
          apiService
            .addStudentInstallment(this.installment)
            .then((response) => {
              if (response != "") {
                this.installment = {
                  id: 0,
                  dueDate: "",
                  paymentDate: "",
                  amount: 0,
                  isPaid: false,
                };

                this.dialog = false;
                this.$emit("studentInstallmentAdded");
              } else {
                this.isClick = false;
              }
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            })
            .finally(() => {
              this.isClick = false;
            });
        }, 3000);

        //this.dialog = false;
      }
    },
  },

  watch: {
    show(val) {
      this.dialog = val;
    },

    studentId(val) {
      this.installment.studentId = val;
    },

    dialog(val) {
      this.$emit("update:show", val);
      const that = this;
      setTimeout(() => {
        that.$refs.form.resetValidation();
      }, 300);
    },
  },
};
</script>
